import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Master = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
    console.log("carrierList",carrierList)
    const[editmodals,setEditModals] = React.useState({show: false, carrier_id:"" ,carrier_name:"",carrier_image:"",})
    const[addImg,setAddImg]=React.useState({uploadPhoto:""})
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
     const[statusState, setstatusState] = React.useState({status:1})
     const[commissionstate, setcommissionState] = React.useState([])

  console.log("statusState",statusState)
  
     const [isImageSelected, setIsImageSelected] = React.useState(false);
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

     const toggleSidebar = () => {
         setIsSidebarOpen(!isSidebarOpen);
         const homeSection = document.querySelector('.home-section');
         if (homeSection) {
             homeSection.classList.toggle('sidebar-closed');
         }
     };
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_list`;
        let sendData = {  dstatus:1,
            indexValue:0,
            limit:0};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
           
            setcarrierList(res.data.output)


        }).catch((e) => {


        });
    }
   
    const changeStatus = (updateData) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_status_carrier`;
        let sendData = {carrier_id:updateData.carrier_id , status:updateData.status };
        console.log("sendData",sendData)
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: "Updated Sucessfully",
                color:"white"
              });
             
          }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                background:"#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color:"white"
              });
               
               });
    }

    const inputHandleChange = (e) => {
        console.log("e ", e.target.name);
        console.log("e ", e.target.value);
        let mediaList = [...carrierList];
        let indexP = carrierList.findIndex(item => item.carrier_id == e.target.name);
        console.log("indexP ", indexP);
        mediaList[indexP].commission = e.target.value;
        console.log("mediaList ", mediaList);
        setcarrierList(mediaList)
    
    }
    const changecommission = (updateData) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/commission_update_api`;
        let sendData = {updateData };
        console.log("sendData",sendData)
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: "Updated Sucessfully",
                color:"white"
              });
            
             
          }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                background:"#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color:"white"
              });
               });
    }
    const updateCommission = () =>{
        changecommission(carrierList)
    }
  return (
    <div>
      <Sub_header/>
      <div style={{marginLeft:"15px",marginRight:"15px"}}>
      <section id="payment_gateway_info" class="container-fluid">
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Settings</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Master Settings</h2>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>
            
    </section>

    


                

    
                <section>
                    <div class="alert alert-warning">
                    <div class="d-flex ">
                        <div class="p-2">
                            <i class="material-icons-outlined">lightbulb</i>
                        </div>
                        <div class="ps-2 ">
                            <ul>
                                <li>Set <strong class="fw-700">commission %</strong> and you will get x% commission on each booking made by the user on the portal. </li>
                                <li>System will add <strong class="fw-700">% value</strong> to carrier pricing and will show the updated value to customer on booking.</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </section>

                <section class="mb-3">
                    <div class="row mast_pri_setup">
                        {carrierList.map((sub,index)=>(
                        <div class="col-12 col-md-4">
                            <div class="card" style={{height:"100%"}}>
                              <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <div>
                                            <img src={`https://cdn.shiprath.in/images/${sub.carrier_image}`} alt="" class="img-fluid" width="50%" />
                                        </div>
                                        {/* <div>
                                            {sub.carrier_name}
                                        </div> */}
                                    </div>
                                    <div class="">
                                        <div class="form-check form-switch  mb-2">
                                        {/* <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="status" defaultChecked={sub.status} onChange={(e) => handleChange(e,sub.carrier_id)} /> */}
                                       <strong>{sub.carrier_name}</strong> 
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <div class="mb-3 mt-3">
                                            <small>Commission %</small>
                                            <div class="input-group">
                                                <span class="input-group-text">%</span>
                                                <input type="number" class="form-control" value={sub.commission} name={sub.carrier_id}   onChange={(e)=>inputHandleChange(e)}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        ))}
                        </div>
                    <div class="mb-5 mt-3" >
                        <button type="button" class="btn btn-primary btn-lg" style={{marginBottom:"20px"}} onClick={updateCommission}>Update</button>
                    </div>
                </section>
    </section>
      </div>  
    </div>
  )
}

export default Master
