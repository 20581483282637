import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Servicable_pincodes_list = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search');
    const {carrier_id} = useParams();
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
    const[editmodals,setEditModals] = React.useState({show: false, carrier_id:"" ,carrier_name:"",carrier_image:"",})
    const[addImg,setAddImg]=React.useState({uploadPhoto:""})
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
     const[statusState, setstatusState] = React.useState({status:1})
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const [selectCarrier, setSelectCarrier] = useState({ carrier_id:"" });
    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" })
    const [isLoading, setIsLoading] = useState(true);
    const [modal,setModal] = useState(false)
  console.log("statusState",statusState)
   const [addpincode,setaddpincode] =useState({pincode:""})
     const [isImageSelected, setIsImageSelected] = React.useState(false);
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

     const toggleSidebar = () => {
         setIsSidebarOpen(!isSidebarOpen);
         const homeSection = document.querySelector('.home-section');
         if (homeSection) {
             homeSection.classList.toggle('sidebar-closed');
         }
     };
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/servicable_pincode_list`;
        let sendData = {  dstatus:1,
            indexValue:index,
            limit:otherStates.rowsPerPage , carrier_id:carrier_id};
            if (search != null && search.length > 0) {
                sendData.searchvalue = search
            } else if (searchfilters.searchtitle != "") {
                sendData.searchvalue = searchfilters.searchtitle
            }
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            setIsLoading(false)
            if(index==0  && otherStates.onload) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
            setcarrierList(res.data.output)


        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
                     Toast.fire({
                            background: "#10b93b",
                            type: 'error',
                            title: "Something Wen Wrong",
                            color: "white"
                        });
            

        });
    }
     
    const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        getCarrierList(newPage);
        
        // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
    }
    
    const stateHandler = (e) => {
        // state[key] = value
        if (e.target.name == "stitle") {

            params.delete('search')
            search = null;
            setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
            //   navigate({ search: params.toString() })

        }
        //    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
        setOtherState({ ...otherStates, onload: true })
    }

    const filter = () => {
        setIsLoading(true)
        search = null
        params.delete('search')
        if (searchfilters.searchtitle !== "") {
            params.append('search', searchfilters.searchtitle)
        }

        // setState({ ...state, isLoading: true })
        navigate({ search: params.toString() })
        getCarrierList(0,true)
    }
    
    const handleChange =(e)=>{
        setaddpincode({pincode:e.target.value})
    }

    const Pincode_add = ()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_servicable_pincode`;
        let sendData = {pincode:addpincode.pincode , carrier_id:carrier_id  };
        console.log("sendData",sendData)
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(res.data.status==true){
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message,
                color:"white"
              });
              getCarrierList(0,true)
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 5000
                  })
                  
                  Toast.fire({
                    background:"#e63900",
                    type: 'error',
                    title: "Something Went Wrong",
                    color:"white"
                  });
                getCarrierList(0,true)
            }
          }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                background:"#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color:"white"
              });
               
            
               });
    }
    const deleteConfirm = (pincode,carrier_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            //console.log("result  ",result);
            if (result.isConfirmed) {
                // setIsLoading(true)
                Delete_pincdoe(pincode,carrier_id)
            }
        })

     }
     const Delete_pincdoe = (pincode,carrier_id) => {
        try {
            
                let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/delete_pincode_servicable`;
                let bodyFormData = { pincode: pincode, carrier_id: carrier_id}

                console.log("bb", bodyFormData);
                axios.post(full_api, bodyFormData, { headers: exportValue.headers }).then((res) => {
                    console.log("res ", res);
                     
                        getCarrierList(0, true);
                    if (res) {
                        Swal.fire(
                            'Good job!',
                            ' Deleted Successfully !',
                            'success'
                        )
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',

                        })
                    }

                    // navigate('/categories')
                    // let newCategory = res.data.category;
                    //setState(state.concat([newCategory]))
                }).catch((e) => {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',

                    })
                });
           
        } catch (e) {
            console.log(e);
        }

    }
  return (
    <div>
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
      
      

       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                               
                            </nav>
                            <h2 className="h4">Servicable Pincodes</h2>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="input-group me-2 me-lg-3 fmxw-400">
                                <span className="input-group-text">
                                    <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                    </svg>
                                </span>
                                <input type="text" className="form-control" placeholder="Search Pincodes" name="stitle" value={searchfilters.searchtitle} onChange={(e) => stateHandler(e)} />
                                <a href="#" class="btn btn-sm btn-gray-800 d-inline-flex align-items-center ms-2" data-bs-toggle="modal" data-bs-target="#import_pincode_special" data-bs-backdrop="false">
            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
            Add Servicable Pincode
        </a>
                            </div>
                           
                        </div>
                    </div>

                    <div class="modal" id="import_pincode_special" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Servicable Pincode</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
     
       
       
       
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Pincode: <span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="" placeholder="Enter Pincode" name="pincode" onChange={(e)=>handleChange(e)} />
        </div>
        <div class="mb-3 mt-3">
         <button className="btn btn-primary" onClick={()=>Pincode_add()}>Submit</button>
        </div>
      </div>
     
    </div>
  </div>
</div>


        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        {/* <th class="border-gray-200">#ID</th>	 */}
                        <th class="border-gray-200">Pincodes</th>
                        <th class="border-gray-200">Action</th>

                       
                    </tr>
                </thead>
                {carrierList.length>0?
                carrierList.map((sub,index)=>(
                <tbody>
                    {/* <!-- Item --> */}
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        {/* <td>
                            <span class="fw-normal"><a href={`/servicable_pincodes/${sub.carrier_id}`}>{sub.carrier_id}</a></span>
                        </td>                        
                        <td>
                            <strong>{sub.carrier_name}</strong>
                        </td>                        
                        <td class="">
                        <img src={`https://cdn.shiprath.in/images/${sub.carrier_image}`} style={{width:"60px"}} className="mt-3"/>
                       <br/> <small className="text-danger">{sub.carrier_name}</small> 
                        </td> */}
                      <td>{sub.pincode}</td>
                      <td>
                                                <div className="btn-group">
                                                    <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="icon icon-sm">
                                                            <span className="fa fa-ellipsis-h icon-dark"></span>
                                                        </span>
                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu py-0">
                                                        
                                                        {/* <a className="dropdown-item" onClick={() => edit_city(item)}><span className="fa fa-edit me-2"></span>Edit</a> */}
                                                        <a className="dropdown-item text-danger rounded-bottom" onClick={() => deleteConfirm(sub.pincode , sub.carrier_id)}><span className="fa  fa-trash me-2"></span>Remove</a>
                                                    </div>
                                                </div>
                                            </td>
                    </tr>
                                                
                </tbody>
                )):"NO DATA FOUND"}
            </table>
            <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
        </div> 
                

</section>




    </div>
  )
}

export default Servicable_pincodes_list
